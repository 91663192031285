@import "_fonts.scss";

@mixin transition($theme: 0.3s ease) {
    -webkit-transition: transform $theme;
    -moz-transition: transform $theme;
    -ms-transition: transform $theme;
    -o-transition: transform $theme;
}
@mixin transform($theme: scale(1.05)) {
    -webkit-transform: $theme;
    -moz-transform: $theme;
    -ms-transform: $theme;
    -o-transform: $theme;
}

p{
    margin-bottom: 0px;
}
.custom_nav{
    padding:0;
    height: 9vh;
    background-color: #1E2761;
    .right_text{
        div h4{
        padding-left: 40px;
        color: #fff;
        @media (max-width: 769px){
            padding-left: 20px;
        }
        }
    }
    h1{
        color: #fff;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .navbar-brand{
        img{
            @media (max-width:481px){
                max-width: 160px;
            }
        }
    }
}
html, body{
    // background-color: #9fea9f;
    // background-color: #dceaf4;
    // background-color: #1e2761;
    background-color: #1e1f22;
    font-size:0.9em;
	font-family: 'robotoregular';
    height: 100vh;
    overflow: hidden;
    @media (max-width:769px){
        height: 100%;
        overflow: auto;
    }   
}
.main{
    height: 100%;
}
.carousel_wrapper{
    height: calc(42vh - 15px);
    margin-bottom: 15px;
    // @media (max-width:76px){
    //     height: 100%;
    // }
    @media (min-width: 1200px) and (max-height:900px){
        height: calc(43.8vh - 15px);
    }
    @media only screen and (width: 1024px)  and (height:1400px){
        height: calc(35vh - 15px);
    }
    @media only screen and (min-width: 1280px) and (orientation: landscape) {
        height: calc(40vh - 15px);
    }
    
}
.slide{
    border: 2px solid #000;
    border-radius: 10px;
    height: 100%;
    // height: 40vh;
    // @media (max-width: 1200px){
    //     height: auto;
    //     margin-bottom: 30px;
    // }
    @media (max-width: 481px){
        height: 300px;
    }
    .carousel-inner{
        border-radius: 10px;
        height: 100%;
    }
}   
.custom_input.form-control{
    // background-color: #408EC6;
    background-color: #1E2761;
    color: white;
    height: 20px;
    width: 50px;
    margin: 0 auto;
    @media (min-width:3840px){
        font-size: 3em;
        padding: 5px;
    }
    @media (min-height:2000px){
        height: 40px;
        width: 100px;
    }
}
.custom_input.form-control:focus{
    // background-color: #408EC6;
    background-color: #1E2761;
    caret-color: #fff;
}
.image_card{
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .1) 0 1px 2px 0;
    position: relative;
    transition: transform 0.3s ease;
    width: 100%;
    @include transition;
    @media (max-width: 769px){
        margin-bottom: 10px;
        margin-top: 10px;
    }
    &:hover{
        transform: scale(1.05);
        @include transform;

    }
    // background-color: #1e2761;
    .card-footer{
        padding: 3px;
    }    
    .card-header{
        // background-color: #fff;        
        background-color: #fff;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1px solid;
        padding: 3px;
        h5{
            font-family: 'DevanagariBold';
            font-weight: bold;
            font-size: 1.2em;
            // @media (min-height:900px){
            //     font-size: 1.8em;
            // }
            @media (max-width: 481px){
                font-size: 1.2em;
            }       
            @media only screen and (width: 1024px)  and (height:1400px){
                font-size: 1.5em;
            }    
            @media (min-width: 3840px)  and (min-height:2160px){
                font-size: 3.5em;
            }
            @media  (min-width: 1900px) and (orientation: landscape){
                font-size: 1.7em;
             }
             @media  (min-width: 3000px) and (orientation: landscape){
                font-size: 3.5em;
             }
            
        }
    }
    .card-body{
        height: calc(8.9vh - 15px);
        padding: 3px;
    //     @media only screen and (min-width: 992px) {
    //         height: 10vh;
    //     }
    //    @media only screen and (min-width: 1200px) {
    //     height: 8vh;
    //    }
    //    @media only screen and (min-height: 900px) {
    //         height: 13.5vh;
    //     }
        @media (max-height:900px){
            height: calc(8.2vh - 15px);
        }
        @media (min-width: 3840px)  and (min-height:2160px){
            height: calc(13.2vh - 15px);
        }
        @media only screen and (min-width: 1280px) and (orientation: landscape) {
            height: calc(12.4vh - 15px);
        }
        @media (min-width: 1900px){
            height: calc(12.4vh - 15px);
        }
        @media only screen and (min-width: 1900px) and (orientation: landscape) {
            height: calc(14.3vh - 15px);
        }
        @media (width: 2560px)  and (height:2160px) and (orientation: landscape){
            height: calc(14.7vh - 15px);
         }
         @media (min-width: 3000px) and (orientation: landscape){
            height: calc(14.9vh - 15px);
         }
         
       
        img{
            width: auto;
            height: 100%;
            margin: 0 auto;
        }
    }
}
.table_wrapper{
    position: relative;
    // margin-top: 30px;
    // margin-bottom: 150px;
    @media (min-width:1024px) and (max-height:769px) {
        margin-bottom: 100px;
    }
    @media (min-width: 3840px)  and (min-height:2160px){
           padding-top: 40px;
    }
    .table_scroll{
        height: calc(100vh - 69vh);
        overflow: auto;       
        @media (max-width:768px){
            height: 250px;
            margin-bottom: 175px;
        }             
        @media (min-width: 1200px) and (max-height:900px){
            height: calc(20vh - 100px);
        }
        @media only screen and (min-width: 1280px) and (orientation: landscape) {
            height: calc(100vh - 69vh);
        }
        
    }
}
.data_table{
    background-color: #fff;
    border: 1px solid;
    margin-bottom: 0px;
    @media (max-width: 768px){
        display: block;
    }
    tr{
        td{
            padding: 0px;
            @media (max-width:768px) {
                width: 33.33%;
                display: inline-block;
                border-bottom: 1px solid #000;
            }
            @media (max-width:480px) {
                width: 50%;
                display: inline-block;
                border-bottom: 1px solid #000;
            }
            @media (max-width:320px) {
                width: 100%;
                display: inline-block;
                border-bottom: 1px solid #000;
            }
        }
    }
}
.flex_box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 5px;
    // @media (max-width: 1400px){
    //     display: block;
    // }
    p{
        font-family: 'robotobold';
        font-size: 1.1em;
        @media (min-width:1900px){
           font-size: 1.4em;     
        }
        @media (min-width: 3840px)  and (min-height:2160px){
           font-size: 2.8em;
        }
    }
    img{
        max-height: 120px;
        width: auto;
        @media (min-width: 1201px) and (max-height:901px){
            max-height: 70px;
        }
        @media only screen and (width: 1025px)  and (height:1401px){
                max-height: 72px;
        } 
        @media (min-width:1900px){
           max-height: 90px; 
        }
        @media (min-width: 3840px)  and (min-height:2160px){
            max-height: 180px;
        }
        @media (min-width:1024px) and (max-height:769px) {
            max-height: 70px;
        }
    }
}
.flex_box_title{
    border-top: 1px solid #000;
    h6{
        text-align: center;
        font-family: 'DevanagariBold';
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 1.5em;
        @media (max-width: 481px){
            font-size: 1.2em;
        }
        @media (min-width: 1200px) and (max-height:900px){
            font-size: 1em;
        }
        @media (min-width:1900px){
           font-size: 1.3em;
        }
        @media (min-width: 3840px)  and (min-height:2160px){
            font-size: 3em;
         }
    }
}
.action_buttons{
    // padding-top: 15px;
    @media (max-width: 481px){
        padding-top: 5px;
    }    
    .btn{
        border-radius: 20px;
        margin-right: 15px;
        min-width: 180px;
        padding: 10px;
        font-size: 1.2em;
        font-family: 'robotobold';
        background-color: #1E2761;
        color:#fff;
        @media (min-width:1900px) and (min-height:1400px) {
            min-width: 190px;
            padding: 15px 30px;
            font-size: 2em;
            border-radius: 50px;
        }
        &:hover{
            background-color: #408EC6;
        }
        @media (max-width: 1500px){
            min-width:150px;
        }
        // @media (max-width: 1201px){
        //     min-width:100px;
        //     font-size: 1em;
        // }
        // @media (max-width: 769px) {
        //     min-width: 100px;
        //     font-size: 0.8em;
        //     padding: 8px;
        //     margin-right: 5px;
        // }
        // @media only screen and (width: 1024px)  and (height:1400px){
        //     min-width: 100px;
        // }
        @media only screen and (min-width: 3840px)  and (min-height:2160px) and (orientation: landscape){
            border-radius: 80px;
            min-width: 198px;
            font-size: 3em;
            padding: 36px 87px;
         }
    }
}
.footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-top: 2px solid #1E2761;
    z-index: 0;
    height: calc(11vh - 10px);
    @media (max-width: 481px){
        padding: 5px;
    }
    @media (max-width:769px){
       height: auto;
    }  
    @media (min-width:1920px) and (orientation: landscape){
        height: calc(8vh - 10px);
    } 
    // @media (min-width: 1200px) and (max-height:900px){
    //     height: calc(11vh - 10px);
    // }
}
.date_time{    
    margin-top: 15px;
    margin-bottom: 15px;
    color: #fff;
    height: calc(5vh - 30px);
    display: flex;
    align-items: center;
    span{
        font-size: 1.8em;
        font-family: 'robotobold';
        &:nth-child(2), &:nth-child(5){
            padding-left: 50px;
        }
        &:last-child
        {
            padding-left: 15px;
        }
        @media (max-width:481px){
            font-size: 1.2em;
        }
        @media (min-width: 3840px)  and (min-height:2160px){
            font-size: 3em;
        }
    }
}
.bottom_input{
    margin-top: 5px;
    @media (max-width: 769px){
        margin-top:0px;
    }
}
.footer_inputs {
    @media (max-width: 769px){
        display: flex;
        justify-content: end;
    }
    .form-control{
        border: 1px solid #1E2761;
        padding: 2px;
        @media (max-width: 769px){
            margin:5px;
        }
        @media (min-width: 3840px)  and (max-width:4000px){
            padding: 2px;
            height: 80px;
            font-size: 3em;
        }
    }
}
.carousel-item{
    position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  perspective: 1000px;
  h2{
    font-family: 'DevanagariBold';
    font-weight: bold;
    font-size: 2em;
    padding-top: 10px;
    @media (min-width: 3840px)  and (min-height:2160px){
        font-size: 5em;
    }
  }
  img{
    height: 75%;
    width: auto;
    margin: 2% auto;
    max-width: 100%;    
  }
  &.flipped img{
    transform-style: preserve-3d; /* Needed for 3D transforms */
    transition: transform 0.5s; /* Smooth transition */
    @include transition($theme: 0.5s ease);
    @include transform($theme:rotateY(180deg));
  }
}

.sparkle_wrapper{
    position: relative;
    width: 100%;
    // display: inline-block;
    canvas{
        display: block;
        position: fixed;
        overflow: hidden;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99999;
        pointer-events: none;
        height: 100vh;
    }
}
.images_wrapper{
    .col{
        display: flex;
        flex: none;
        width: 20%;
        @media (max-width:769px){
            width: 33.33%;
        }
        @media (max-width:501px){
            width: 50%;
        }
        @media only screen and (max-width:1080px) and (max-height:1024px){
            width: 33.33%;
        }
    }   
}

.flex_actions{
    display: flex;
    align-items: center;
    &.start{
        justify-content: flex-start;
    }
    &.end{
        justify-content: flex-end;
    }
    .form-control{
        width: 80px;
        height: 40px;
        @media only screen and (min-width: 3840px)  and (min-height:2160px) and (orientation: landscape){
            width: 100px;
            height: 80px;
         }
    }
}

#jackpotModal{
    top: calc(100vh - 72%);
    .modal-header{
        .modal-title{
            color:red;
            font-weight: 600;
        }
    }
    .modal_content{
        img{
            max-height: 200px;
            width: auto;
            margin: 0 auto;
        }
        h3{
            font-family: 'DevanagariBold';
            font-weight: bold;
            margin-top: 15px;
        }
    }
}
