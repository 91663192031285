@font-face {
  font-family: "robotoblack";
  src: url("../css/fonts/roboto/roboto-black-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoblack_italic";
  src: url("../css/fonts/roboto/roboto-blackitalic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold";
  src: url("../css/fonts/roboto/roboto-bold-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold_italic";
  src: url("../css/fonts/roboto/roboto-bolditalic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoitalic";
  src: url("../css/fonts/roboto/roboto-italic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight";
  src: url("../css/fonts/roboto/roboto-light-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight_italic";
  src: url("../css/fonts/roboto/roboto-lightitalic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium";
  src: url("../css/fonts/roboto/roboto-medium-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium_italic";
  src: url("../css/fonts/roboto/roboto-mediumitalic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoregular";
  src: url("../css/fonts/roboto/roboto-regular-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin";
  src: url("../css/fonts/roboto/roboto-thin-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin_italic";
  src: url("../css/fonts/roboto/roboto-thinitalic-webfont.woff2") format("woff2"), url("../css/fonts/roboto/roboto-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Devanagari";
  src: url("../css/fonts/devanagari/regular/Devanagari.woff2") format("woff2"), url("../css/fonts/devanagari/regular/Devanagari.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DevanagariBold";
  src: url("../css/fonts/devanagari/DevanagariBold.woff2") format("woff2"), url("../css/fonts/devanagari/DevanagariBold.woff2") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
p {
  margin-bottom: 0px;
}

.custom_nav {
  padding: 0;
  height: 9vh;
  background-color: #1E2761;
}
.custom_nav .right_text div h4 {
  padding-left: 40px;
  color: #fff;
}
@media (max-width: 769px) {
  .custom_nav .right_text div h4 {
    padding-left: 20px;
  }
}
.custom_nav h1 {
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 481px) {
  .custom_nav .navbar-brand img {
    max-width: 160px;
  }
}

html, body {
  background-color: #1e1f22;
  font-size: 0.9em;
  font-family: "robotoregular";
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 769px) {
  html, body {
    height: 100%;
    overflow: auto;
  }
}

.main {
  height: 100%;
}

.carousel_wrapper {
  height: calc(42vh - 15px);
  margin-bottom: 15px;
}
@media (min-width: 1200px) and (max-height: 900px) {
  .carousel_wrapper {
    height: calc(43.8vh - 15px);
  }
}
@media only screen and (width: 1024px) and (height: 1400px) {
  .carousel_wrapper {
    height: calc(35vh - 15px);
  }
}
@media only screen and (min-width: 1280px) and (orientation: landscape) {
  .carousel_wrapper {
    height: calc(40vh - 15px);
  }
}

.slide {
  border: 2px solid #000;
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 481px) {
  .slide {
    height: 300px;
  }
}
.slide .carousel-inner {
  border-radius: 10px;
  height: 100%;
}

.custom_input.form-control {
  background-color: #1E2761;
  color: white;
  height: 20px;
  width: 50px;
  margin: 0 auto;
}
@media (min-width: 3840px) {
  .custom_input.form-control {
    font-size: 3em;
    padding: 5px;
  }
}
@media (min-height: 2000px) {
  .custom_input.form-control {
    height: 40px;
    width: 100px;
  }
}

.custom_input.form-control:focus {
  background-color: #1E2761;
  caret-color: #fff;
}

.image_card {
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
  position: relative;
  transition: transform 0.3s ease;
  width: 100%;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
}
@media (max-width: 769px) {
  .image_card {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.image_card:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.image_card .card-footer {
  padding: 3px;
}
.image_card .card-header {
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid;
  padding: 3px;
}
.image_card .card-header h5 {
  font-family: "DevanagariBold";
  font-weight: bold;
  font-size: 1.2em;
}
@media (max-width: 481px) {
  .image_card .card-header h5 {
    font-size: 1.2em;
  }
}
@media only screen and (width: 1024px) and (height: 1400px) {
  .image_card .card-header h5 {
    font-size: 1.5em;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .image_card .card-header h5 {
    font-size: 3.5em;
  }
}
@media (min-width: 1900px) and (orientation: landscape) {
  .image_card .card-header h5 {
    font-size: 1.7em;
  }
}
@media (min-width: 3000px) and (orientation: landscape) {
  .image_card .card-header h5 {
    font-size: 3.5em;
  }
}
.image_card .card-body {
  height: calc(8.9vh - 15px);
  padding: 3px;
}
@media (max-height: 900px) {
  .image_card .card-body {
    height: calc(8.2vh - 15px);
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .image_card .card-body {
    height: calc(13.2vh - 15px);
  }
}
@media only screen and (min-width: 1280px) and (orientation: landscape) {
  .image_card .card-body {
    height: calc(12.4vh - 15px);
  }
}
@media (min-width: 1900px) {
  .image_card .card-body {
    height: calc(12.4vh - 15px);
  }
}
@media only screen and (min-width: 1900px) and (orientation: landscape) {
  .image_card .card-body {
    height: calc(14.3vh - 15px);
  }
}
@media (width: 2560px) and (height: 2160px) and (orientation: landscape) {
  .image_card .card-body {
    height: calc(14.7vh - 15px);
  }
}
@media (min-width: 3000px) and (orientation: landscape) {
  .image_card .card-body {
    height: calc(14.9vh - 15px);
  }
}
.image_card .card-body img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.table_wrapper {
  position: relative;
}
@media (min-width: 1024px) and (max-height: 769px) {
  .table_wrapper {
    margin-bottom: 100px;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .table_wrapper {
    padding-top: 40px;
  }
}
.table_wrapper .table_scroll {
  height: 31vh;
  overflow: auto;
}
@media (max-width: 768px) {
  .table_wrapper .table_scroll {
    height: 250px;
    margin-bottom: 175px;
  }
}
@media (min-width: 1200px) and (max-height: 900px) {
  .table_wrapper .table_scroll {
    height: calc(20vh - 100px);
  }
}
@media only screen and (min-width: 1280px) and (orientation: landscape) {
  .table_wrapper .table_scroll {
    height: 31vh;
  }
}

.data_table {
  background-color: #fff;
  border: 1px solid;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .data_table {
    display: block;
  }
}
.data_table tr td {
  padding: 0px;
}
@media (max-width: 768px) {
  .data_table tr td {
    width: 33.33%;
    display: inline-block;
    border-bottom: 1px solid #000;
  }
}
@media (max-width: 480px) {
  .data_table tr td {
    width: 50%;
    display: inline-block;
    border-bottom: 1px solid #000;
  }
}
@media (max-width: 320px) {
  .data_table tr td {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #000;
  }
}

.flex_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 5px;
}
.flex_box p {
  font-family: "robotobold";
  font-size: 1.1em;
}
@media (min-width: 1900px) {
  .flex_box p {
    font-size: 1.4em;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .flex_box p {
    font-size: 2.8em;
  }
}
.flex_box img {
  max-height: 120px;
  width: auto;
}
@media (min-width: 1201px) and (max-height: 901px) {
  .flex_box img {
    max-height: 70px;
  }
}
@media only screen and (width: 1025px) and (height: 1401px) {
  .flex_box img {
    max-height: 72px;
  }
}
@media (min-width: 1900px) {
  .flex_box img {
    max-height: 90px;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .flex_box img {
    max-height: 180px;
  }
}
@media (min-width: 1024px) and (max-height: 769px) {
  .flex_box img {
    max-height: 70px;
  }
}

.flex_box_title {
  border-top: 1px solid #000;
}
.flex_box_title h6 {
  text-align: center;
  font-family: "DevanagariBold";
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.5em;
}
@media (max-width: 481px) {
  .flex_box_title h6 {
    font-size: 1.2em;
  }
}
@media (min-width: 1200px) and (max-height: 900px) {
  .flex_box_title h6 {
    font-size: 1em;
  }
}
@media (min-width: 1900px) {
  .flex_box_title h6 {
    font-size: 1.3em;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .flex_box_title h6 {
    font-size: 3em;
  }
}

@media (max-width: 481px) {
  .action_buttons {
    padding-top: 5px;
  }
}
.action_buttons .btn {
  border-radius: 20px;
  margin-right: 15px;
  min-width: 180px;
  padding: 10px;
  font-size: 1.2em;
  font-family: "robotobold";
  background-color: #1E2761;
  color: #fff;
}
@media (min-width: 1900px) and (min-height: 1400px) {
  .action_buttons .btn {
    min-width: 190px;
    padding: 15px 30px;
    font-size: 2em;
    border-radius: 50px;
  }
}
.action_buttons .btn:hover {
  background-color: #408EC6;
}
@media (max-width: 1500px) {
  .action_buttons .btn {
    min-width: 150px;
  }
}
@media only screen and (min-width: 3840px) and (min-height: 2160px) and (orientation: landscape) {
  .action_buttons .btn {
    border-radius: 80px;
    min-width: 198px;
    font-size: 3em;
    padding: 36px 87px;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-top: 2px solid #1E2761;
  z-index: 0;
  height: calc(11vh - 10px);
}
@media (max-width: 481px) {
  .footer {
    padding: 5px;
  }
}
@media (max-width: 769px) {
  .footer {
    height: auto;
  }
}
@media (min-width: 1920px) and (orientation: landscape) {
  .footer {
    height: calc(8vh - 10px);
  }
}

.date_time {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
  height: calc(5vh - 30px);
  display: flex;
  align-items: center;
}
.date_time span {
  font-size: 1.8em;
  font-family: "robotobold";
}
.date_time span:nth-child(2) {
  padding-left: 20px;
}
.date_time span:nth-child(3), .date_time span:nth-child(4), .date_time span:last-child {
  padding-left: 50px;
}
@media (max-width: 481px) {
  .date_time span {
    font-size: 1.2em;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .date_time span {
    font-size: 3em;
  }
}

.bottom_input {
  margin-top: 5px;
}
@media (max-width: 769px) {
  .bottom_input {
    margin-top: 0px;
  }
}

@media (max-width: 769px) {
  .footer_inputs {
    display: flex;
    justify-content: end;
  }
}
.footer_inputs .form-control {
  border: 1px solid #1E2761;
  padding: 2px;
}
@media (max-width: 769px) {
  .footer_inputs .form-control {
    margin: 5px;
  }
}
@media (min-width: 3840px) and (max-width: 4000px) {
  .footer_inputs .form-control {
    padding: 2px;
    height: 80px;
    font-size: 3em;
  }
}

.carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  perspective: 1000px;
}
.carousel-item h2 {
  font-family: "DevanagariBold";
  font-weight: bold;
  font-size: 2em;
  padding-top: 10px;
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .carousel-item h2 {
    font-size: 5em;
  }
}
.carousel-item img {
  height: 75%;
  width: auto;
  margin: 2% auto;
  max-width: 100%;
}
.carousel-item.flipped img {
  transform-style: preserve-3d; /* Needed for 3D transforms */
  transition: transform 0.5s; /* Smooth transition */
  -webkit-transition: transform 0.5s ease;
  -moz-transition: transform 0.5s ease;
  -ms-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}

.sparkle_wrapper {
  position: relative;
  width: 100%;
}
.sparkle_wrapper canvas {
  display: block;
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  pointer-events: none;
  height: 100vh;
}

.images_wrapper .col {
  display: flex;
  flex: none;
  width: 20%;
}
@media (max-width: 769px) {
  .images_wrapper .col {
    width: 33.33%;
  }
}
@media (max-width: 501px) {
  .images_wrapper .col {
    width: 50%;
  }
}
@media only screen and (max-width: 1080px) and (max-height: 1024px) {
  .images_wrapper .col {
    width: 33.33%;
  }
}

.flex_actions {
  display: flex;
  align-items: center;
}
.flex_actions.start {
  justify-content: flex-start;
}
.flex_actions.end {
  justify-content: flex-end;
}
.flex_actions .form-control {
  width: 80px;
  height: 40px;
}
@media only screen and (min-width: 3840px) and (min-height: 2160px) and (orientation: landscape) {
  .flex_actions .form-control {
    width: 100px;
    height: 80px;
  }
}

#jackpotModal {
  top: calc(100vh - 80%);
}
#jackpotModal .modal-header .modal-title {
  color: red;
  font-weight: 600;
  width: 100%;
}
#jackpotModal .modal_content img {
  max-height: 200px;
  width: auto;
  margin: 0 auto;
}
#jackpotModal .modal_content h3 {
  font-family: "DevanagariBold";
  font-weight: bold;
  margin-top: 15px;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it's above other content */
  display: none; /* Initially hidden */
}

.overlay.active {
  display: block; /* Show overlay when active */
}