@font-face {
    font-family: 'robotoblack';
    src: url('../../fonts/roboto/roboto-black-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoblack_italic';
    src: url('../../fonts/roboto/roboto-blackitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('../../fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold_italic';
    src: url('../../fonts/roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoitalic';
    src: url('../../fonts/roboto/roboto-italic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotolight';
    src: url('../../fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotolight_italic';
    src: url('../../fonts/roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    src: url('../../fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium_italic';
    src: url('../../fonts/roboto/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('../../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotothin';
    src: url('../../fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotothin_italic';
    src: url('../../fonts/roboto/roboto-thinitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Devanagari';
    src: url('../../fonts/Devnagari/regular/Devanagari.woff2') format('woff2'),
        url('../../fonts/Devnagari/regular/Devanagari.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DevanagariBold';
    src: url('../../fonts/Devnagari/DevanagariBold.woff2') format('woff2'),
        url('../../fonts/Devnagari/DevanagariBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}